import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  // Router needed for not showing Header in login Example
  router: string;
  constructor(
    private _router: Router,
    private titleService: Title

    ) {

    titleService.setTitle('FuD-Webinar');
    this.router = _router.url;

  }
}


