import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MerklisteService {

  // wird bei toggle der Merkliste getriggered
  private MessageCountSubject: Subject<number> = new Subject<number>();
  MessageCount$: Observable<number> = this.MessageCountSubject.asObservable();

  merklisteIds: string[] = [];
  // dictionnary ids to object
  merklisteDict: any = {};
  doc: string;


  constructor() {}

  public registerItem(document: any) {
    const documentId = document['_source']['ID'];
    this.merklisteIds.push(documentId);
    this.merklisteDict[documentId] = document;
    this._emitMerklistCount();
  }

  public unregisterItem(document: string) {
    const documentId = document['_source']['ID'];
    this.merklisteIds = this.merklisteIds.filter((subId: string) => {
      return subId !== documentId;
    });
    delete this.merklisteDict[documentId];
    this._emitMerklistCount();
  }

  private _emitMerklistCount() {
    this.MessageCountSubject.next(this.merklisteIds.length);
  }

  public getItemIds(): string[] {
    return this.merklisteIds;
  }

  public getObjectById(id: string): any {
    return this.merklisteDict[id];
  }

  public getAllObjects(): any[] {
    const allObjects = [];
    this.merklisteIds.forEach((subId: string) => {
      allObjects.push(this.merklisteDict[subId]);
    });
    return allObjects;
  }

  public hasId(documentId): boolean {
    return this.merklisteIds.indexOf(documentId) > -1;
  }

}
