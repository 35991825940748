import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-cart-dialog',
  templateUrl: './cart-dialog.component.html',
  styleUrls: ['./cart-dialog.component.scss']
})
export class CartDialogComponent implements OnInit {


  status: false;
  isDisabled: false;


  constructor(
  ) { }

  ngOnInit() {
  }



// clickEvent() {
//   this.status = !this.status;
//   this.isDisabled = !this.isDisabled;
// }

}
