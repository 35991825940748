/**
 * Builds queries by adding and removing facets
 */

import { Injectable } from '@angular/core';
import { FudElasticQuery } from '../../typings/fud-elastic-query';
import { SearchSettings, FacetSetting } from '../../typings/search-settings';



@Injectable({
  providedIn: 'root'
})
export class QueryBuilderService {

  firstload: boolean;
  currQuery: FudElasticQuery;
  facetObj: any;
  validDocTypes: any[] = [];
  sortArray: any[] = [];
  operator: any;
  type: any;
  savedTerms: any;
  savedTopics: any;
  savedSort: any;
  savedCat: any;
  savedOpt: any;
  savedQuerytext: any;
  savedDates: any;
  savedDateVon: any;
  savedDateBis: any;
  dates: any[];

  constructor(
  ) {

  }

  public setFirstload(firstload) {
    this.firstload = firstload;
  }

  public getFirstload() {
    return this.firstload;
  }

  public setQuery(query: FudElasticQuery) {
    console.log(query);
    this.currQuery = query;
  }

  public getQuery(): FudElasticQuery {
    console.log(this.currQuery);
    return this.currQuery;
  }

  public setTerms(terms) {
    this.savedTerms = terms;
    console.log(this.savedTerms);
  }

  public getTerms() {
    console.log(this.savedTerms);
   return this.savedTerms;
  }

  public setTopics(topics) {
    this.savedTopics = topics;
    console.log(this.savedTopics);
  }

  public getTopics() {
    console.log(this.savedTopics);
    return this.savedTopics;
   }

   public setDates(von, bis) {
    this.savedDateVon = von;
    this.savedDateBis = bis;
  }

  public getDates() {
    if (this.savedDateVon !== undefined && this.savedDateBis !== undefined) {
      return [this.savedDateVon, this.savedDateBis];
    } else {
      return undefined;
    }
  }



  public setSort(sort) {
    this.savedSort = sort;
  }

  public getSort() {
   return this.savedSort;
  }

  public setQuerytext(Querytext) {
    this.savedQuerytext = Querytext;
  }

  public getQuerytext() {
   return this.savedQuerytext;
  }

  public setCat(Cat) {
    this.savedCat = Cat;
  }

  public getCat() {
   return this.savedCat;
  }

  public setOpt(Opt) {
    this.savedOpt = Opt;
  }

  public getOpt() {
   return this.savedOpt;
  }




  public setSettings(settings: SearchSettings) {
    const currFacetObj = {};
    // build the facet objects to be returned
    // from the query
    settings.facets.forEach((facetSetting: FacetSetting) => {
        facetSetting.facetKeys.forEach((facetKey: string) => {
          currFacetObj[facetKey] = {
            'terms': {
              'field': facetKey,
              'size' : 99
          }
          };
        });
    });
    this.facetObj = currFacetObj;
    settings.validDocTypes.forEach((docType: string) => {
      this.validDocTypes.push({
        'type': {
          'value': docType
        }
      });
    });
  }





  public buildQuery(
    searchText: string,
    selectedCategory: any,
    selectedSorting: any,
    selectedOparator: any,
    date_von: any,
    date_bis: any,
    facetPhrases: any,
    pageSize: number,
    pageIndex: number): FudElasticQuery {

      console.log(selectedOparator);

      if (selectedSorting === undefined) {
        selectedSorting = 'relevance';
      }
    const should = facetPhrases;
    const topicBooleans = [];
    const range = [];
    // tslint:disable-next-line:forin
    for (const member in facetPhrases) {
      // tslint:disable-next-line:forin
      for (const subMember in facetPhrases[member]) {
        topicBooleans.push({
          'bool': {
            'should': facetPhrases[member][subMember]
          }
        });
      }
    }



    if (date_von && date_bis) {
      range.push({
          'bool': {
              'must': [
                  {
                      'range': {
                          '2_datum_neu': {
                              'gte': date_von
                          }
                      }
                  },
                  {
                      'range': {
                          '2_datum_neu': {
                              'lte': date_bis
                          }
                      }
                  }
              ]
          }
      },
      {
       'bool': {
              'must': [
                  {
                      'range': {
                          '4_datum_von': {
                              'gte': date_von
                          }
                      }
                  },
                  {
                      'range': {
                          '4_datum_bis': {
                              'lte': date_bis
                          }
                      }
                  }
              ]
          }
    });
  }




    let textQuery: any;
    if (!searchText) {
      textQuery = { 'match_all': {} };
    } else {

    selectedOparator = JSON.stringify(selectedOparator);
    console.log(selectedOparator);

    if ( selectedOparator === '"and"') {
    this.operator = 'and';
    }

    if ( selectedOparator === '"or"') {
    this.operator = 'or';
    }

    if ( selectedOparator === '"genau"') {
      console.log(selectedOparator);

      this.type = 'phrase_prefix';
    } else {
      console.log(selectedOparator);

      this.type = 'best_fields';
    }

      textQuery = {
        'multi_match': {
          'query': searchText,
          'operator': this.operator,
          'type': this.type,
          'fields': selectedCategory
        }
      };
    }

    const must = [
            textQuery
    ];



    selectedSorting = JSON.stringify(selectedSorting);
    console.log(selectedSorting);

    if (selectedSorting === '"relevance"') {
      this.sortArray = [

      ];
    }

    if (selectedSorting === '"abs"') {
      this.sortArray = [
        { '2_sort_adressee.raw' : {'order' : 'asc'}},
        '_score'
      ];
  }
  if ( selectedSorting === '"empf"') {
    this.sortArray = [
        { '2_sort_creator.raw' : {'order' : 'asc'}},
        '_score'
      ];
  }


  if ( selectedSorting === '"name"') {
    this.sortArray = [
        { '4_sort_personen.raw' : {'order' : 'asc'}},
        '_score'
      ];

  }

  if ( selectedSorting === '"date"') {
    this.sortArray = [
        { '2_datum_neu.raw' : {'order' : 'desc'}},
        '_score'
      ];
  }


   if (pageIndex === 0) {
    pageIndex = 0;
  } else {
    pageIndex = pageIndex * pageSize;
  }




  // loading from saved Query
  if ( this.firstload === true && this.currQuery !== undefined) {
    const query: FudElasticQuery = {
      'size': this.currQuery.size,
      'from': this.currQuery.from,
      'sort': this.currQuery.sort,
      'query': this.currQuery.query,
      'facets': this.currQuery.facets,
    };
    this.setQuery(query);
    return query;
  } else {
    const query: FudElasticQuery = {
      'size': pageSize,
      'from': pageIndex,
      'sort': this.sortArray,
      'query': {
        'filtered': {
          'filter': {
            'bool': {
              'must': [
                {
                  'bool': {
                    'should': this.validDocTypes
                  },
                }
              ]
            }
          },
          'query': {
            'bool': {
              'must': {
                'bool': {
                  'must': must,
                  'should': [
                    {
                      'bool': {
                        'must': topicBooleans,
                      },
                    },
                    range
                  ],
                  'minimum_should_match': 2
                }
              }
            }
          }
        }
      },
      'facets': this.facetObj,
    };
    this.setQuery(query);
    console.log(query);
    return query;
  }
  }



}
