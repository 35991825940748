import { Component, OnInit, ElementRef, AfterViewChecked, EventEmitter } from '@angular/core';
import { AfterViewInit} from '@angular/core';
import { HttpService } from '../services/http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { QuerystoreService } from '../services/querystore.service';
import { MerklisteService } from '../services/merkliste.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as OpenSeadragon from 'openseadragon';
import { Lightbox } from 'ngx-lightbox';



// import { Renderer2 } from '@angular/core';
@Component({
  selector: 'app-document-view',
  templateUrl: './document-view.component.html',
  styleUrls: ['./document-view.component.scss']
})


export class DocumentViewComponent implements OnInit, AfterViewInit, AfterViewChecked {

  private testElement: ElementRef;

  osdviewer: any;
  osdviewerLoaded: boolean;
  selectedIndex: any;
  hits: string[];

  copy: any;
  elementHtml: string;

  sammlung = [];

  urheberfilter: string[];
  message: any;

  document: any;
  queryNav = [];
  QueryName: any;
  QueryID: any;
  QueryFotos: any;
  license: string;
  checked: boolean;
  placesVerortung = [];
  placesStandort_aktuell = [];
  placesKirchenkreis = [];
  placesStandort_ehem = [];

  prevItem: string;
  nextItem: string;
  isLoadingResults = false;
  public div: HTMLDivElement;
  documentDoctyp2: any;
  nachweise2: any[] = [];
  inventarisierung: any[] = [];
  relation: any[] = [];
  digital: any[] = [];
  anhang: any[] = [];
  variante: any[] = [];
  ereignisse: any[] = [];
  nachweise4: any[] = [];

  location: any[] = [];
  shorttitle: any[] = [];
  creators: any[] = [];
  rights: any[] = [];
  _albums: any[] = [];
  belege: any[] = [];
  kategorie: any[] = [];
  verificationpst: any[] = [];
  verificationpend: any[] = [];
  orte: any[] = [];
  persons: any[] = [];
  keywords: any[] = [];
  belegeEreigniss: any[] = [];
  beziehungen: any[] = [];
  person_relation_ref: any[] = [];
  preverification: any[] = [];
  prelation: any[] = [];
  prelendverifi: any[] = [];
  prelbeginverifi: any[] = [];
  person_reference: any[] = [];
  anhang4: any[] = [];
  sachKommentar: any[] = [];
  textKommentar: any[] = [];

  infoRequest: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private http: HttpService,
    private route: ActivatedRoute,
    private router: Router,
    private hitsStore: QuerystoreService,
    private merklisteService: MerklisteService,
    public snackBar: MatSnackBar,
    private el: ElementRef,
    private _lightbox: Lightbox
  ) {
    this.merklisteService.MessageCount$.subscribe((merklisteCount: number) => {
      this.checked = this.merklisteService.hasId(this.route.snapshot.paramMap.get('id'));
    });
  }

  // LESSON-PETER: Die ganze Logik war im constructor, allgemein
  //   empfiehlt es sich die Logik in ngOnInit zu packen, teils
  //   wurde das Dokument nicht geladen
  ngOnInit() {
    this.osdviewerLoaded = false;
    this.isLoadingResults = true;
    this.checked = this.merklisteService.hasId(this.route.snapshot.paramMap.get('id'));
    this.http.currentMessage.subscribe(message => this.message = message);

  if (this.message.hits) {
    this.message.hits.forEach(element => {
    });
  }



    const id = this.route.snapshot.paramMap.get('id');



    this.hits = this.hitsStore.getAllHits();

    if (this.hits) {
      for (let i = 0; i < this.hits.length; i++) {
        if (this.hits[i] === id) {
          this.nextItem = this.hits[i + 1];
          this.prevItem = this.hits[i - 1];
        }
      }
    }

    this.http.documentDoctyp2(id).subscribe(res => {
      if (res.found === true) {
        this.document = res;
        console.log(this.document);
        this.isLoadingResults = false;



        if (this.document['_source']['notes']) {
          this.document['_source']['notes'].forEach(item => {
            if (item['categoryID'] && item['categoryID'] === '1') {
                this.sachKommentar.push(
                    item
                );
            }
          });
        }

        if (this.document['_source']['notes']) {
          this.document['_source']['notes'].forEach(item => {
            if (item['categoryID'] && item['categoryID'] === '2') {
              this.textKommentar.push(
                      item
                  );
            }
          });
        }


        // if (this.document['_source']['2_Relation']) {
        //   this.document['_source']['2_Relation'].forEach(item => {
        //     this.http.documentDoctyp2(item.relation_internallink).subscribe(doctyp2 => {
        //       analyse
        //     });
        //   });
        // }





// 2_Nachweis, 4_Nachweis
// reference_fudlink (brief)

// 2_Nachweis ,4_Nachweis
// reference_shorttitle
// Analyse
// LmAdd:
// lm-zoterolink

// 4_Beziehungen
// prelation2
// Analyse
// LmAdd
// Doclink /fudLink

// 4_Namensvarianten
// pnsourcefud
// Doctyp2 verlinkung


// 4_Ereignisse
// peventreffud
// Doctyp2 verlinkung




        // Weitere Datumsangaben Inventarisierung
        if (this.document['_source']['2_Inventarisierung']) {
          this.document['_source']['2_Inventarisierung'].forEach(item => {
            if (item['date_variant_type']) {
              item['date_variant_type'].forEach(value => {
                this.inventarisierung.push(
                  {
                    cat: value.content,
                    items: []
                  }
                );
              });
            }
          });
        }

        this.inventarisierung = this.inventarisierung.filter((element, index, self) =>
          index === self.findIndex((t) => (
            t.cat === element.cat
        )));

        if (this.document['_source']['2_Inventarisierung']) {
          this.document['_source']['2_Inventarisierung'].forEach(item => {
            item['date_variant_type'].forEach(art => {
              this.inventarisierung.forEach(category => {
                if (art.content === category.cat) {
                  item.date_variant_verification.forEach(element => {
                        category.items.push(
                          {
                            date: item.date_variant,
                            text: item.date_variant_text,
                            verification: element.content,
                            notes: item.date_variant_notes
                          }
                        );
                  });
                }
              });
            });
          });
        }
        this.inventarisierung.forEach(element => {
          element.items.sort((a, b) => a.date.localeCompare(b.date));
        });

        console.log(this.inventarisierung);















         // Weitere Datumsangaben Nachweise
         if (this.document['_source']['2_Nachweis']) {
          this.document['_source']['2_Nachweis'].forEach(item => {
            if (item['reference_type']) {
              item['reference_type'].forEach(value => {
                this.nachweise2.push(
                  {
                    cat: value.content,
                    items: []
                  }
                );
              });
            }
          });
        }

        this.nachweise2 = this.nachweise2.filter((element, index, self) =>
          index === self.findIndex((t) => (
            t.cat === element.cat
        )));

        if (this.document['_source']['2_Nachweis']) {
          this.document['_source']['2_Nachweis'].forEach(item => {
            item['reference_type'].forEach(art => {
              this.nachweise2.forEach(category => {
                if (art.content === category.cat) {

                  if (item['reference_location']) {
                    item['reference_location'].forEach(element => {
                      this.location.push(element.content);
                    });
                  }

                  if (item['reference_shorttitle']) {
                  item['reference_shorttitle'].forEach(element => {
                    console.log(element.ID);
                    this.http.analyseById(element.ID).subscribe(analyse => {
                      console.log(analyse);
                      if (element.content && analyse['_source'].LmAdd && analyse['_source'].LmAdd['lm-zoterolink']) {
                        this.shorttitle.push({
                          name: element.content,
                          link: analyse['_source'].LmAdd['lm-zoterolink']
                         });
                        }
                      });
                  });
                }

                console.log('short' + this.shorttitle );



                  if (item.reference_fudlink) {
                    this.http.analyseById(item.reference_fudlink).subscribe(analyse => {


                      category.items.push(
                        {
                          location: this.location,
                            signature: item.reference_signature,
                            details: item.reference_details,
                            shortTitle: this.shorttitle,
                            link: item.reference_link,
                            linkdate: item.reference_linkdate,
                            notiz: item.literaturenotiz
                        }
                      );
                    });
                  } else {
                    category.items.push(
                      {
                        location: this.location,
                            signature: item.reference_signature,
                            details: item.reference_details,
                            shortTitle: this.shorttitle,
                            link: item.reference_link,
                            linkdate: item.reference_linkdate,
                            notiz: item.literaturenotiz
                      }
                    );
                  }


                  this.location = [];
                  this.shorttitle = [];

                }
              });
            });
          });
        }

        this.nachweise2.sort((a, b) => a.cat.localeCompare(b.cat));










        // Weitere Datumsangaben Relation verknüpfte dokumente
        if (this.document['_source']['2_Relation']) {
          this.document['_source']['2_Relation'].forEach(item => {
            if (item['relation_type']) {
              item['relation_type'].forEach(value => {
                this.relation.push(
                  {
                    cat: value.content,
                    items: []
                  }
                );
              });
            }
          });
        }

        this.relation = this.relation.filter((element, index, self) =>
          index === self.findIndex((t) => (
            t.cat === element.cat
        )));

        if (this.document['_source']['2_Relation']) {
          this.document['_source']['2_Relation'].forEach(item => {
            item['relation_type'].forEach(art => {
              this.relation.forEach(category => {
                if (art.content === category.cat) {
                  if (item.relation_internallink) {
                    this.http.analyseById(item.relation_internallink).subscribe(analyse => {
                      category.items.push(
                        {
                          internalName: analyse['_source'].content,
                          internalId: item.relation_internallink,
                          external: item.relation_externallink,
                          notes: item.relation_notes,
                        }
                      );
                    });
                  } else {
                    category.items.push(
                      {
                        external: item.relation_externallink,
                        notes: item.relation_notes,
                      }
                    );
                  }

                }
              });
            });
          });
        }

        // this.relation.sort((a, b) => a.cat.localeCompare(b.cat));



// Weitere Datumsangaben Relation verknüpfte dokumente
if (this.document['_source']['2_Digitalisate']) {
  this.document['_source']['2_Digitalisate'].forEach(item => {
    if (item['digital_copy_representation_typ']) {
      item['digital_copy_representation_typ'].forEach(value => {
        this.digital.push(
          {
            cat: value.content,
            items: []
          }
        );
      });
    }
  });
}

this.digital = this.digital.filter((element, index, self) =>
  index === self.findIndex((t) => (
    t.cat === element.cat
)));

if (this.document['_source']['2_Digitalisate']) {
  this.document['_source']['2_Digitalisate'].forEach(item => {
    item['digital_copy_representation_typ'].forEach(art => {
      this.digital.forEach(category => {
        if (art.content === category.cat) {

              category.items.push(
                {
                  title: item.digital_copy_title,
                  details: item.digital_copy_representation_details
                }
              );
        }
      });
    });
  });
}

// this.digital.sort((a, b) => a.cat.localeCompare(b.cat));



// Weitere Datumsangaben Relation verknüpfte dokumente
if (this.document['_source']['2_Anhang']) {
  this.document['_source']['2_Anhang'].forEach(item => {
    if (item['attachment_type']) {
      item['attachment_type'].forEach(value => {
        this.anhang.push(
          {
            cat: value.content,
            items: []
          }
        );
      });
    }
  });
}

this.anhang = this.anhang.filter((element, index, self) =>
  index === self.findIndex((t) => (
    t.cat === element.cat
)));

if (this.document['_source']['2_Anhang']) {
  this.document['_source']['2_Anhang'].forEach(item => {
    item['attachment_type'].forEach(art => {
      this.anhang.forEach(category => {
        if (art.content === category.cat) {

          if (item['attachment_creators']) {
            item['attachment_creators'].forEach(element => {
              this.creators.push(element.content);
            });
          }
          if (item['attachment_rights']) {
          item['attachment_rights'].forEach(element => {
             this.rights.push(element.content);
            });
          }

              category.items.push(
                {
                  title: item.attachment_title,
                  details: item.attachment_file,
                  creators: this.creators,
                  rights: this.rights,
                  notes: item.attachment_notes,
                  link: item.attachment_link,
                  linkDate: item.attachment_linkdate
                }
              );

              this.creators = [];
              this.rights = [];
        }
      });
    });
  });
}

// this.anhang.sort((a, b) => a.cat.localeCompare(b.cat));


      }
    });

    this.http.documentDoctyp4(id).subscribe(res => {
      if (res.found === true) {
        this.document = res;
        console.log(this.document);



        if (this.document['_source']['notes']) {
          this.document['_source']['notes'].forEach(item => {
            if (item['categoryID'] && item['categoryID'] === '1') {
                this.sachKommentar.push(
                    item
                );
            }
          });
        }

        if (this.document['_source']['notes']) {
          this.document['_source']['notes'].forEach(item => {
            if (item['categoryID'] && item['categoryID'] === '2') {
              this.textKommentar.push(
                      item
                  );
            }
          });
        }


        // Weitere Datumsangaben Relation verknüpfte dokumente
        if (this.document['_source']['4_Namensvarianten']) {
          this.document['_source']['4_Namensvarianten'].forEach(item => {
            if (item['person_namevariant_type']) {
              item['person_namevariant_type'].forEach(value => {
                this.variante.push(
                  {
                    cat: value.content,
                    items: []
                  }
                );
              });
            }
          });
        }

        this.variante = this.variante.filter((element, index, self) =>
          index === self.findIndex((t) => (
            t.cat === element.cat
        )));

        if (this.document['_source']['4_Namensvarianten']) {
          this.document['_source']['4_Namensvarianten'].forEach(item => {
            item['person_namevariant_type'].forEach(art => {
              this.variante.forEach(category => {
                if (art.content === category.cat) {

                  if (item['pnsourceref']) {
                    item['pnsourceref'].forEach(element => {
                      this.belege.push(element.content);
                    });
                  }


                      category.items.push(
                        {
                          titel: item.ptitledesignsub,
                          vorname: item.pnamefirstsub,
                          nachname: item.pnamefamilysub,
                          zusatz: item.pnamecomponentsub,
                          verwendetVon: item.pusedfromsub,
                          verwendetBis: item.pusedtosub,
                          belege1: item.pnsourcefud,
                          belege2: this.belege,
                          uri1: item.pauthorithysub,
                          uri2: item.pauthorithysub
                        }
                      );

                }
              });
            });
          });
        }

        // this.variante.sort((a, b) => a.cat.localeCompare(b.cat));









        if (this.document['_source']['4_Ereignisse']) {
          this.document['_source']['4_Ereignisse'].forEach(item => {
            if (item['person_events']) {
              item['person_events'].forEach(value => {
                this.ereignisse.push(
                  {
                    cat: value.content,
                    sortDate: item.pstdatesub,
                    items: []
                  }
                );
              });
            }
          });
        }

        this.ereignisse = this.ereignisse.filter((element, index, self) =>
          index === self.findIndex((t) => (
            t.cat === element.cat
        )));

        if (this.document['_source']['4_Ereignisse']) {
          this.document['_source']['4_Ereignisse'].forEach(item => {
            item['person_events'].forEach(art => {
              this.ereignisse.forEach(category => {
                if (art.content === category.cat) {

                  if (item['person_category']) {
                    item['person_category'].forEach(element => {
                      this.kategorie.push(element.content);
                    });
                  }

                  if (item['pstverificationsub']) {
                    item['pstverificationsub'].forEach(element => {
                      this.verificationpst.push(element.content);
                    });
                  }

                  if (item['pendverificationsub']) {
                    item['pendverificationsub'].forEach(element => {
                      this.verificationpend.push(element.content);
                    });
                  }

                  if (item['pplacesindex']) {
                    item['pplacesindex'].forEach(element => {
                      this.orte.push(element.content);
                    });
                  }

                  if (item['ppersindex']) {
                    item['ppersindex'].forEach(element => {
                      this.persons.push(element.content);
                    });
                  }

                  if (item['peventkeyw']) {
                    item['peventkeyw'].forEach(element => {
                      this.keywords.push(element.content);
                    });
                  }

                  if (item['peventref']) {
                    item['peventref'].forEach(element => {
                      this.belegeEreigniss.push(element.content);
                    });
                  }

                      category.items.push(
                        {
                          category: this.kategorie,
                          subject: item.psubjectsub,
                          date1: item.pstdatesub,
                          date2: this.verificationpst,
                          date3: this.verificationpend,
                          date4: item.penddatesub,
                          orte: this.orte,
                          persons: this.persons,
                          keywords: this.keywords,
                        }
                      );

                      this.verificationpst = [];
                      this.verificationpend = [];
                      this.kategorie = [];
                      this.orte = [];
                      this.persons = [];
                      this.keywords = [];

                }
              });
            });
          });
        }

        // this.ereignisse.sort((a, b) => a.sortDate.localeCompare(b.sortDate));









        if (this.document['_source']['4_Beziehungen']) {
          this.document['_source']['4_Beziehungen'].forEach(item => {
            if (item['person_relationtype']) {
              item['person_relationtype'].forEach(value => {
                this.beziehungen.push(
                  {
                    cat: value.content,
                    items: []
                  }
                );
              });
            }
          });
        }

        this.beziehungen = this.beziehungen.filter((element, index, self) =>
          index === self.findIndex((t) => (
            t.cat === element.cat
        )));

        if (this.document['_source']['4_Beziehungen']) {
          this.document['_source']['4_Beziehungen'].forEach(item => {
            item['person_relationtype'].forEach(art => {
              this.beziehungen.forEach(category => {
                if (art.content === category.cat) {

                  if (item['prelation2']) {
                    item['prelation2'].forEach(element => {
                      this.prelation.push(element.content);
                    });
                  }

                  if (item['prelbeginverifi']) {
                    item['prelbeginverifi'].forEach(element => {
                      this.prelbeginverifi.push(element.content);
                    });
                  }

                  if (item['prelendverifi']) {
                    item['prelendverifi'].forEach(element => {
                      this.prelendverifi.push(element.content);
                    });
                  }

                  if (item['person_relation_ref']) {
                    item['person_relation_ref'].forEach(element => {
                      this.person_relation_ref.push(element.content);
                    });
                  }

                      category.items.push(
                        {
                          type: art.content,
                          prelation: this.prelation,
                          von: item.prelbegindate,
                          bis: item.prelenddate,
                          preVeri: this.prelbeginverifi,
                          endVeri: this.prelendverifi,
                          prelationreffud: item.prelationreffud,
                          prelationrefnote: item.prelationrefnote,
                          relation: this.person_relation_ref,
                        }
                      );

                      this.prelation = [];
                      this.prelbeginverifi = [];
                      this.prelendverifi = [];
                      this.person_relation_ref = [];


                }
              });
            });
          });
        }




        this.beziehungen.sort((a, b) => a.type.localeCompare(b.type));




      // Weitere Datumsangaben Nachweise
      if (this.document['_source']['4_Nachweise']) {
        this.document['_source']['4_Nachweise'].forEach(item => {
          if (item['ptyperef']) {
            item['ptyperef'].forEach(value => {
              this.nachweise4.push(
                {
                  cat: value.content,
                  items: []
                }
              );
            });
          }
        });
      }

      this.nachweise4 = this.nachweise4.filter((element, index, self) =>
        index === self.findIndex((t) => (
          t.cat === element.cat
      )));

      if (this.document['_source']['4_Nachweise']) {
        this.document['_source']['4_Nachweise'].forEach(item => {
          item['ptyperef'].forEach(art => {
            this.nachweise4.forEach(category => {
              if (art.content === category.cat) {

                if (item['person_reference']) {
                  item['person_reference'].forEach(element => {
                    this.person_reference.push(element.content);
                  });
                }

                if (item['reference_shorttitle']) {
                  item['reference_shorttitle'].forEach(element => {
                  this.shorttitle.push(
                    {
                      name: element.content,
                      link: element.LmAdd.zoterolink
                    }
                    );
                  });
                }


                  category.items.push(
                    {
                          person_reference: this.person_reference,
                          ppagessub: item.ppagessub,
                          plinklibrarysub: item.plinklibrarysub,
                          pnotesref: item.pnotesref,
                          shortTitle: this.shorttitle,
                    }
                  );

                  this.person_reference = [];

              }
            });
          });
        });
      }

      this.nachweise4.sort((a, b) => a.cat.localeCompare(b.cat));








// Weitere Datumsangaben Relation verknüpfte dokumente
if (this.document['_source']['4_Anhang']) {
  this.document['_source']['4_Anhang'].forEach(item => {
    if (item['attachment_type']) {
      item['attachment_type'].forEach(value => {
        this.anhang4.push(
          {
            cat: value.content,
            items: []
          }
        );
      });
    }
  });
}

this.anhang4 = this.anhang4.filter((element, index, self) =>
  index === self.findIndex((t) => (
    t.cat === element.cat
)));

if (this.document['_source']['4_Anhang']) {
  this.document['_source']['4_Anhang'].forEach(item => {
    item['attachment_type'].forEach(art => {
      this.anhang4.forEach(category => {
        if (art.content === category.cat) {

          if (item['attachment_creators']) {
            item['attachment_creators'].forEach(element => {
              this.creators.push(element.content);
            });
          }
          if (item['attachment_rights']) {
          item['attachment_rights'].forEach(element => {
             this.rights.push(element.content);
            });
          }

              category.items.push(
                {
                  title: item.attachment_title,
                  details: item.attachment_file,
                  creators: this.creators,
                  rights: this.rights,
                  notes: item.attachment_notes,
                  link: item.attachment_link,
                  linkDate: item.attachment_linkdate
                }
              );

              this.creators = [];
              this.rights = [];
        }
      });
    });
  });
}

this.anhang4.sort((a, b) => a.cat.localeCompare(b.cat));




        this.isLoadingResults = false;
      }
    });




}








public highlightNotes(event: any, indexItem: any, prefix: string, className: string) {
  let id = indexItem.ID;
  if (indexItem.selected === undefined) {
    indexItem.selected = true;
  } else {
    indexItem.selected = !indexItem.selected;
  }
  let selected = indexItem.selected;

  // if (this.highlightLinks) {
    let nativeEl: HTMLElement = this.el.nativeElement;
    let spans = nativeEl.getElementsByClassName(prefix + id);
    let currSpan;
    // keep track if scrolled, to scroll into view
    let scrolled = false;
    for (let i = 0; i < spans.length; i++) {
      currSpan = spans.item(i);
      // currSpan.scrollIntoView();
      if (selected) {
        currSpan.classList.add(className);
        if (!scrolled) {
          currSpan.scrollIntoView();
          scrolled = true;
        }
      } else {
        currSpan.classList.remove(className);
      }
    }
  // }
}

public stripTags(content: string) {
  content = content.replace(/<\/?.*?>/g, "");
  return content;
}





ngAfterViewChecked(): void {

    this.openSeaDragon();
}

ngAfterViewInit() {
  this.osdviewerLoaded = false;
}


openSeaDragon() {


  if (!this.osdviewerLoaded && !this.isLoadingResults) {
    if (this.document && this.document['_source'] && this.document['_source']['2_Digitalisate']) {
      const tileSourcesArray = [];

      if (this.osdviewer) {
        this.osdviewer.destroy();
      }

      this.document['_source']['2_Digitalisate'].forEach(element => {


        // console.log(element);
        if (element.digital_copy_file.filename) {
          const ipath = 'assets/vmimg/' + element.digital_copy_file.filename;
          tileSourcesArray.push(
            {
              type: 'image',
              url: ipath
            }
          );
        }
        console.log(tileSourcesArray);

      });





      this.osdviewer = OpenSeadragon({
        id: 'docopenseadragon',
        prefixUrl: 'assets/openseadragon/images/',
        tileSources: tileSourcesArray,
        sequenceMode: true,
        gestureSettingsMouse: {
          clickToZoom: true
        }
      });
      this.osdviewerLoaded = true;
    }
  }
}

  toDocument(id) {
    this.router.navigateByUrl('/' , {skipLocationChange: true}).then(() =>
    this.router.navigate(['/document/' + id]));
  }

  prev() {
    this.router.navigateByUrl('/' , {skipLocationChange: true}).then(() =>
    this.router.navigate(['/document/' + this.prevItem]));
  }

  next() {
    this.router.navigateByUrl('/' , {skipLocationChange: true}).then(() =>
    this.router.navigate(['/document/' + this.nextItem]));
  }

  open(image): void {

      const src = '/assets/vmimg/anhang/' + image;
      const caption = image;
      const thumb = '/assets/vmimg/anhang/' + image;
      const album = {
         src: src,
         caption: caption,
         thumb: thumb
      };

      this._albums.push(album);
      console.log(this._albums);
    this._lightbox.open(this._albums, 0);
    this._albums = [];
  }


  public onMerklistToggle(event: any, document: any) {
    const snackBarMessage: string = (event.checked) ?
      'Zur Merkliste hinzugefügt' : 'Von Merkliste entfernt';
    this.snackBar.open(snackBarMessage, '', {
      duration: 1000
    });
    if (event.checked) {
      this.merklisteService.registerItem(document);
    } else {
      this.merklisteService.unregisterItem(document);
    }
  }

  printDoc() {
    window.print();
  }

}


