import { Component, OnChanges, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { MatSnackBar } from '@angular/material/snack-bar';

import { MerklisteService } from '../services/merkliste.service';
import { HttpService } from '../services/http.service';


@Component({
  selector: 'app-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss']
})
export class ListItemComponent implements OnInit, OnChanges {

  @Input()
  document: any;



  @Output()
  infoRequest: EventEmitter<string> = new EventEmitter<string>();


  zwischenablage: any;

  checked: boolean;
  placesStandort_aktuell = [];
  placesVerortung = [];
  placesKirchenkreis = [];
  getNewParentID = '';
  i: number;

  constructor(
    private merklisteService: MerklisteService,
    public snackBar: MatSnackBar,
    public http: HttpService
  ) {
    this.merklisteService.MessageCount$.subscribe((merklisteCount: number) => {
      this.checked = this.merklisteService.hasId(this.document['_source']['ID']);
    });


    // console.log('tesssstttinnngggg = ' + this.document['_source']['ID']);

  }


  ngOnInit() {
    this.checked = this.merklisteService.hasId(this.document['_source']['ID']);
if (this.document['_source']['79_verortung']) {
    if (this.document['_source']['79_verortung']['parentID'] !== '0') {
      this.http.analyseById(this.document['_source']['79_verortung']['parentID']).subscribe(res => {
        this.placesVerortung.unshift(res['_source']['content']);
        const getParent = (parentID) => {
          this.http.analyseById(parentID).subscribe(analyse => {
            if (analyse['_source']) {
              this.placesVerortung.unshift(analyse['_source']['content']);
              let newParentID = analyse['_source']['parentID'];
              if (newParentID !== '0') {
                getParent(newParentID);
            }
            }
          });
      };
      let firstParentID = res['_source']['parentID'];
      getParent(firstParentID);
      });
    }
  }

    if (this.document['_source']['79_standort_aktuell']) {
      if (this.document['_source']['79_standort_aktuell']['parentID'] !== '0') {
        this.http.analyseById(this.document['_source']['79_standort_aktuell']['parentID']).subscribe(res => {
          this.placesStandort_aktuell.unshift(res['_source']['content']);
          const getParent = (parentID) => {
            this.http.analyseById(parentID).subscribe(analyse => {
              if (analyse['_source']) {
                this.placesStandort_aktuell.unshift(analyse['_source']['content']);
                let newParentID = analyse['_source']['parentID'];
                if (newParentID !== '0') {
                  getParent(newParentID);
              }
              }
            });
        };
        let firstParentID = res['_source']['parentID'];
        getParent(firstParentID);
        });
      }}

if (this.document['_source']['79_kirchenkreis']){
  if (this.document['_source']['79_kirchenkreis']['parentID'] !== '0') {
    this.http.analyseById(this.document['_source']['79_kirchenkreis']['parentID']).subscribe(res => {
      this.placesKirchenkreis.unshift(res['_source']['content']);
      const getParent = (parentID) => {
        this.http.analyseById(parentID).subscribe(analyse => {
          if (analyse['_source']) {
            this.placesKirchenkreis.unshift(analyse['_source']['content']);
            let newParentID = analyse['_source']['parentID'];
            if (newParentID !== '0') {
              getParent(newParentID);
          }
          }
        });
    };
    let firstParentID = res['_source']['parentID'];
    getParent(firstParentID);
    });
  }
  }
}




  ngOnChanges(changes: any) {
    // console.log(changes);
  }



  public onMerklistToggle(event: any, document: any) {
    const snackBarMessage: string = (event.checked) ?
      'Zur Merkliste hinzugefügt' : 'Von Merkliste entfernt';
    this.snackBar.open(snackBarMessage, '', {
      duration: 1000
    });
    if (event.checked) {
      this.merklisteService.registerItem(document);
    } else {
      this.merklisteService.unregisterItem(document);
    }
  }



  public onInfoClick() {
    this.infoRequest.emit(this.document['_source']['ID']);
  }



  public copyLink(text, id) {

    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = '<a href="/suche/document/' + id + '">' + text + '</a>';
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);



    this.snackBar.open('Link erzeugt (Zwischenablage)', 'close', {
      duration: 2000,
    });
}




}
