
import { SearchSettings } from '../../typings/search-settings';

export const FretSearchSettings: SearchSettings = {
    facets: [

        {
            id: 'personen',
            title: 'Personen',
            facetKeys: [
                '2_facet_personen',
                '4_facet_personen'
            ]
        },
        {
            id: 'raumindex',
            title: 'Raumindex',
            facetKeys: [
                '2_facet_orte',
                '4_facet_orte',
            ]
        },
        {
            id: 'schlagworte',
            title: 'Schlagworte',
            facetKeys: [
                '2_facet_schlagworte',
                '4_facet_schlagworte'
            ]
        },
        {
            id: 'typ',
            title: 'Typ',
            facetKeys: [
                '2_facet_typ',
            ]
        },
        {
            id: 'sprache',
            title: 'Sprache',
            facetKeys: [
                '2_facet_sprache',
                '4_facet_sprache'
            ]
        },
        {
            id: 'anhang',
            title: 'mit Anhang',
            facetKeys: [
                '2_facet_anhang',
                '4_facet_anhang'
            ]
        },
        {
            id: 'volltext',
            title: 'mit Volltext',
            facetKeys: [
                '2_facet_volltext',
                '4_facet_volltext'
            ]
        },

    ],
    validDocTypes: ['doctyp2', 'doctyp4']
};
