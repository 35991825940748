import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { Observable, BehaviorSubject } from 'rxjs';



@Injectable({
  providedIn: 'root'
})

export class HttpService {

  private messageSource = new BehaviorSubject('keine Query');
  currentMessage = this.messageSource.asObservable();
  allIds: any;
  body: any;
  bodyAllIds: any;
  bodySearch: any;

  constructor(
    private http: HttpClient) {
}


changeMessage(message: string) {

  this.messageSource.next(message);

}



   Search(body: any): Observable<any> {
    const proxyBody = {
      body: JSON.stringify(body)
    };
     return this.http.post( environment.elastic_url_search, proxyBody, {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
        })
    });
   }




   documentDoctyp2(id: any): any {
    const proxyBody = {
      body: id
    };
     return this.http.post( environment.elastic_url_document2, proxyBody, {
      headers: new HttpHeaders({
      'Content-Type':  'application/json'
    })
  });

    }

    documentDoctyp4(id: any): any {

      const proxyBody = {
        body: id
      };
       return this.http.post( environment.elastic_url_document4, proxyBody, {
        headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    });
      }



    analyseById(id: any): any {

      const proxyBody = {
        body: id
      };
       return this.http.post( environment.elastic_url_analyse, proxyBody, {
        headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    });
      }
}
