import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QuerystoreService {

  allObjects: string[] = [];
  allHits: any;

  private messageSource = new BehaviorSubject('keine Query');
  currentMessage = this.messageSource.asObservable();

  constructor() { }




  gettingHits(message: any) {
    const allObjects = [];
    message.forEach((hit: string) => {
      allObjects.push(hit['_id']);
  });
    console.log(allObjects);
    this.allHits = allObjects;
    return this.allObjects;
  }


  public getAllHits(): any[] {
    return this.allHits;
  }



}
