import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { QueryBuilderService } from 'src/app/services/query-builder.service';


export interface Category {
  value: any;
  viewValue: string;
}

interface Categorys extends Array<Category> {}

export interface Sorting {
  value: any;
  viewValue: string;
}

export interface Operator {
  value: any;
  viewValue: string;
}



@Component({
  selector: 'app-text-search',
  templateUrl: './text-search.component.html',
  styleUrls: ['./text-search.component.scss']
})
export class TextSearchComponent implements OnInit {

  queryText: string;

  // Notiz keine Arrays in die Interfaces

  categories: Category[] = [
    { value: '_all', viewValue: 'Volltext' },
    { value: ['4_pre_personen', '2_pre_personen'], viewValue: 'Personen' },
    { value: ['2_pre_orte', '4_pre_orte'], viewValue: 'Orte' },
    { value: ['2_pre_schlagworte', '4_pre_schlagworte'], viewValue: 'Schlagworte' },
    { value: '2_pre_briefserie', viewValue: 'Briefserie' },
    { value: '2_pre_volltext', viewValue: 'im Text' },
  ];
  selectedCategory = this.categories[0].value;

  sortings: Sorting[] = [
    { value: 'relevance', viewValue: 'Relevanz' },
    { value: 'empf', viewValue: 'nach Empfänger' },
    { value: 'abs', viewValue: 'nach Absender' },
    { value: 'name', viewValue: 'nach Name ' },
    { value: 'date', viewValue: 'nach Datum' }
  ];
  selectedSorting = this.sortings[0].value;


  operators: Operator[] = [
    { value: 'genau', viewValue: 'genau' },
    { value: 'and', viewValue: 'und' },
    { value: 'or', viewValue: 'oder' },
  ];
  selectedOperator = this.operators[0].value;

  @Input()
  count: number;

  // TODO: should be typed to the emit
  @Output()
  search: EventEmitter<any> = new EventEmitter<any>();

  constructor( private queryBuilder: QueryBuilderService) { }

  ngOnInit() {

  //  this.selectedCategory = ['79_analyse_aktuell', '79_analyse_kirchen'];

    if (this.queryBuilder.getCat() !== undefined) {
      this.selectedCategory = this.queryBuilder.getCat();
    }

    if (this.queryBuilder.getOpt() !== undefined) {
      this.selectedOperator = this.queryBuilder.getOpt();
    }

    if (this.queryBuilder.getSort() !== undefined) {
      this.selectedSorting = this.queryBuilder.getSort();
    }

    if (this.queryBuilder.getQuerytext() !== undefined) {
      this.queryText = this.queryBuilder.getQuerytext();
    }

  }

  onSelectCat(event) {
    this.queryBuilder.setCat(event.value);
  }

  onSelectOpt(event) {
    this.queryBuilder.setOpt(event.value);
  }

  onSelectSort(event) {
    this.queryBuilder.setSort(event.value);
  }

  onSearchChange(event) {
    // console.log(event);
    this.queryBuilder.setQuerytext(event);
  }


  // onSelectOper(event) {
  //   this.queryBuilder.setOper(event.value);
  // }


  submitSearch() {

console.log(this.selectedOperator);

    this.search.emit({
      queryText: this.queryText,
      category: this.selectedCategory,
      sorting: this.selectedSorting,
      operator: this.selectedOperator
    });

  }

}
