import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from '../services/http.service';
import { FudElasticQuery } from '../../typings/fud-elastic-query';
import { QueryBuilderService } from '../services/query-builder.service';
import { FretSearchSettings } from '../config/fret-search-settings';
import { SearchSettings, FacetSetting } from '../../typings/search-settings';
import { Category } from './text-search/text-search.component';
import { Sorting } from './text-search/text-search.component';
import { FilterEvent } from './filter/filter.event';
import { FilterComponent } from './filter/filter.component';
import { Operator } from './text-search/text-search.component';
import { QuerystoreService } from '../services/querystore.service';
import { MediaMatcher } from '@angular/cdk/layout';

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/



export interface FacetResult {
  term: string;
  count: number;
  selected: boolean;
}


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  mobileQuery: MediaQueryList;

  searchSettings: SearchSettings = FretSearchSettings;

  selectedCategory: Category;
  selectedSorting: Sorting;
  selectedOperator: Operator;

  facetDict: any = {};
  // will map facet ids (e.g. '134_facet_location') to
  // the keyword ('location') by iterating over the facetKeys
  facetIdsToId: any = {};

  // (uses object notation, for each facetId a list of selected topics)
  selectedTopics = {};

  isLoadingResults = false;

  @ViewChild(FilterComponent) filter;


  private documents: Document[] = [];


  // MatPaginator Output
  pageSize: any = 25;
  pageIndex: any = 0;
  lowValue: any = '0';
  count: number;
  highValue: any = '50';
  pageSizeOptions: number[] = [5, 10, 25, 100];
  currentYear = new Date().getUTCFullYear(); // 2020
  date_von_year: any = '0100';
  date_bis_year: any = this.currentYear;

  date_von: any;
  date_bis: any;


  queryText: string;
  isMobile: boolean;
  firstload: boolean;
  dates: any;
  length: number;



  constructor(

    private http: HttpService,
    private hitsStore: QuerystoreService,
    private queryBuilder: QueryBuilderService,
    private router: Router,
    private media: MediaMatcher

  ) {
    this.mobileQuery = media.matchMedia('(min-width: 769px)');
    this.count = this.length;
  }

  ngOnInit() {

this.isLargeScreen();
this.queryBuilder.setFirstload(this.firstload = true);
console.log(this.firstload);
console.log(this.selectedSorting);

// if (this.firstload === true) {
// this.date_von_year = '0100';
// this.date_bis_year = '2000';
// }


    this.searchSettings.facets.forEach((facet: FacetSetting) => {
      // populate facetDict used to pass information to filter boxes
      this.facetDict[facet.id] = {
        'keys': facet.facetKeys,
        'values': [],
        'selectedTerms': []
      };

      // Map all facet keys to the id (to check for existence on return)
      facet.facetKeys.forEach((facetKey: string) => {
        this.facetIdsToId[facetKey] = facet.id;
      });
    });


    if (this.queryBuilder.getTopics() !== undefined) {
      this.selectedTopics = this.queryBuilder.getTopics();
    }

    if (this.queryBuilder.getTerms() !== undefined) {
      this.facetDict = this.queryBuilder.getTerms();
    }

    if (this.queryBuilder.getDates() !== undefined) {
      console.log(this.queryBuilder.getDates());
      this.dates = this.queryBuilder.getDates();
      this.date_von_year = this.dates[0];
      this.date_bis_year = this.dates[1];
    }


    this.queryBuilder.setSettings(this.searchSettings);
    this.submitSearch();

  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(',')
      .map(str => +str);

      console.log('pageSizeOptions = ' + this.pageSizeOptions);
  }


  // getting selected Facets
  onSelectionChange(event: FilterEvent) {
    // console.log(event);
    this.selectedTopics[event.type] = event.topics;
    this.facetDict[event.type]['selectedTerms'] = event.selectedTerms;

    this.queryBuilder.setTopics(this.selectedTopics);
    this.queryBuilder.setTerms(this.facetDict);

    this.submitSearch();
  }

  onSelectionDateVon() {
    this.queryBuilder.savedDateVon(this.date_von);
  }

  onSelectionDateBis() {
    this.queryBuilder.savedDateBis(this.date_bis);
  }


  getPaginatorData(event) {

    if (event.pageIndex === this.pageIndex + 1) {
      this.lowValue = this.lowValue + event.pageSize;
      this.highValue = this.highValue + event.pageSize;
    } else if (event.pageIndex === this.pageIndex - 1) {
      this.lowValue = this.lowValue - event.pageSize;
      this.highValue = this.highValue - event.pageSize;
    }
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    console.log(this.pageIndex);
    this.submitSearch();
  }


  // TODO Bester weg für den Facet reset
 resetFacets() {
    // console.log(this.filter.facets.active);
    this.filter.facets.active = [];
    // console.log(this.filter.facets.active);
    this.submitSearch();
  }

  public onInfoRequest(documentId: string) {
    this.router.navigate(['document', documentId]);
  }

  public onTextSearch(searchText: any) {
    this.queryText = searchText.queryText;
    this.selectedCategory = searchText.category;
    this.selectedSorting = searchText.sorting;
    this.selectedOperator = searchText.operator;
    console.log(this.selectedSorting);
    this.submitSearch();
  }


  submitSearch(event?) {
    this.queryBuilder.setDates(this.date_von_year, this.date_bis_year);
    this.date_von = new Date(this.date_von_year + '-01-01');
    this.date_bis = new Date(this.date_bis_year + '-12-31');
    console.log(this.selectedSorting);
    if ( this.selectedSorting === undefined) {
      // tslint:disable-next-line:no-unused-expression
      this.selectedSorting;
    }

    const query: FudElasticQuery = this.queryBuilder.buildQuery(
      this.queryText,
      this.selectedCategory,
      this.selectedSorting,
      this.selectedOperator,
      this.date_von,
      this.date_bis,
      this.selectedTopics,
      this.pageSize,
      this.pageIndex
    );

    // console.log(this.date_von.value.toISOString().split('T')[0]);
    // console.log(this.date_bis.value.toISOString().split('T')[0]);



    this.http.Search(query).subscribe(
      data => {

          console.log(data.allIds);
          console.log(data);
          console.log(query);

          this.http.changeMessage(data.hits);
          this.hitsStore.gettingHits(data.allIds);

      }
  );







    this.http.Search(query).subscribe(
      (response: any) => {
        this.isLoadingResults = true;
        setTimeout(() => {
          // console.log(query);
          // console.log(response.took);

          // TODO: necessary? used to rebuild data-table?
          // could possibly use a refresh method
          this.pageIndex = this.pageIndex;
          this.pageSize = this.pageSize;
          this.length = this.length;
          this.isLoadingResults = false;
          this.documents = response.hits.hits;
          this.count = response.hits.total;
console.log('search.component = ' + this.pageSize);

console.log(response.facets);
          if (!response.facets) {
            return false;
          }

          // temporary object to collect all information
          // to avoid triggering change-detection too often
          const tmpFacets = {};
          let currId: string;
          // tslint:disable-next-line:forin
          for (let facetId in response.facets) {
            currId = this.facetIdsToId[facetId];
            // console.log(`${facetId} => ${currId}`);
            if (currId !== undefined) {
              if (tmpFacets[currId] === undefined) {
                tmpFacets[currId] = [];
              }
              tmpFacets[currId] = tmpFacets[currId].concat(
                response.facets[facetId].terms);
            }
          }

          // tslint:disable-next-line:forin
          for (let facetId in tmpFacets) {
            tmpFacets[facetId] = this._reduceFacets(
              tmpFacets[facetId], facetId);
          }
          // Now actually assign them to the facetDict (triggering change detection)
          // tslint:disable-next-line:forin
          for (let facetId in tmpFacets) {
            this.facetDict[facetId].values = tmpFacets[facetId];
          }
        }, 500);
      }, (error: any) => {
        console.error(error);
      });

      this.queryBuilder.setFirstload(this.firstload = false);
  }

  /**
   * Reduce all facets by setting defaults and adding up values
   */
  private _reduceFacets(facets: FacetResult[], keywordId: string): { active: FacetResult[], inactive: FacetResult[] } {
    // using reducer on all terms
    const mergedFacets = {};
    // console.log(facets);
    facets.forEach((facet: FacetResult) => {
      facet.count = facet.count || 0;
      if (mergedFacets[facet.term] === undefined) {
        mergedFacets[facet.term] = Object.assign(facet, {});
      } else {
        mergedFacets[facet.term].count = mergedFacets[facet.term].count + facet.count;
      }
    });
    const activeFacets: FacetResult[] = [];
    const inactiveFacets: FacetResult[] = [];
    Object.keys(mergedFacets).forEach((facetKey: string) => {

      if (this.facetDict[keywordId].selectedTerms.indexOf(facetKey) > -1) {
        activeFacets.push(mergedFacets[facetKey]);
      } else {
        inactiveFacets.push(mergedFacets[facetKey]);
      }
    });

    activeFacets.sort(function (b, a) { return a.count - b.count; });
    inactiveFacets.sort(function (b, a) { return a.count - b.count; });
    return {
      active: activeFacets,
      inactive: inactiveFacets
    };
  }


  onPrint() {
    window.print();
}


resetSearch() {
  window.open('/', '_self', '');
}

isLargeScreen() {
  const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  if (width > 720) {
      return true;
  } else {
      return false;
  }
}

}
