import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SearchComponent } from './search/search.component';
import { DocumentViewComponent } from './document-view/document-view.component';

const routes: Routes = [
  { path: '', redirectTo: '/suche', pathMatch: 'full'},
  { path: 'suche', component: SearchComponent},
  { path: 'document/:id', component: DocumentViewComponent , runGuardsAndResolvers: 'always'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    onSameUrlNavigation: 'reload',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
