import { Component, OnInit } from '@angular/core';
import { MerklisteDialogComponent } from '../merkliste-dialog/merkliste-dialog.component';

import { MerklisteService } from '../services/merkliste.service';
import { CartDialogComponent } from '../cart-dialog/cart-dialog.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  count: number;
  constructor(
    private merklisteService: MerklisteService,
    public dialog: MatDialog
  ) {
    merklisteService.MessageCount$.subscribe(
      (count: number) => this.count = count);
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(MerklisteDialogComponent, {
      width: '900px'
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      console.log("CLOSING RESULT");
      console.log(result);
    });
    console.log(dialogRef);
  }

  openDialogCart(): void {
    const dialogRef = this.dialog.open(CartDialogComponent, {
      width: '900px',
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      console.log('The dialog was closed');
    });
  }


  ngOnInit() {
  }

}
